import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdminRoutingModule } from "./admin-routing.module";
import { MembershipComponent } from "./pages/membership/membership.component";
import { UserHttpService } from "./services/user.http.service";
import { DirectiveModule } from "src/app/shared/modules/directive.module";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { PaymentService } from "./services/payment.http.service";
import { TataLifecycleComponent } from "./pages/tata-lifecycle/tata-lifecycle.component";
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { FormsModule } from "@angular/forms";
import { SubscriptionHttpService } from "./services/subscription.http.service";
import { ProviderHttpService } from "./services/provider.http.service";
import { ManageSubscriptionsComponent } from './modals/manage-subscriptions/manage-subscriptions.component';
import { ManageUserEsimComponent } from './modals/manage-user-esim/manage-user-esim.component';
import { ManageUserDetailsComponent } from './modals/manage-user-details/manage-user-details.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ViewPaymentHistoryComponent } from './modals/view-payment-history/view-payment-history.component';
import { PromoCodesComponent } from './pages/promo-codes/promo-codes.component';
import { PromoCodeHttpService } from "./services/promo-code.http.service";
import { EditPromoCodeComponent } from './modals/edit-promo-code/edit-promo-code.component';
import { CreatePromoCodeComponent } from './modals/create-promo-code/create-promo-code.component';
import { SettingsHttpService } from "./services/settings.http.service";
import { SettingsComponent } from './pages/settings/settings/settings.component';
import { EditByteFlowSettingsComponent } from './modals/edit-byte-flow-settings/edit-byte-flow-settings.component';
import { EsimManagementComponent } from './pages/esim-management/esim-management.component';
import { ErrorTrackerComponent } from './pages/error-tracker/error-tracker.component';
import { ByteFlowComponent } from './pages/byte-flow/byte-flow.component';

@NgModule({
    declarations: [
        MembershipComponent,
        PaymentsComponent,
        TataLifecycleComponent,
        UserManagementComponent,
        ManageSubscriptionsComponent,
        ManageUserEsimComponent,
        ManageUserDetailsComponent,
        ProgressBarComponent,
        ViewPaymentHistoryComponent,
        PromoCodesComponent,
        EditPromoCodeComponent,
        CreatePromoCodeComponent,
        SettingsComponent,
        EditByteFlowSettingsComponent,
        EsimManagementComponent,
        ErrorTrackerComponent,
        ByteFlowComponent
    ],
    imports: [
        CommonModule,
        AdminRoutingModule,
        DirectiveModule,
        FormsModule
    ],
    providers: [
        UserHttpService,
        PaymentService,
        SubscriptionHttpService,
        ProviderHttpService,
        PromoCodeHttpService,
        SettingsHttpService
    ]
})
export class AdminModule { }
