import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MembershipComponent } from "./pages/membership/membership.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { TataLifecycleComponent } from "./pages/tata-lifecycle/tata-lifecycle.component";
import { UserManagementComponent } from "./pages/user-management/user-management.component";
import { PromoCodesComponent } from "./pages/promo-codes/promo-codes.component";
import { SettingsComponent } from "./pages/settings/settings/settings.component";
import { EsimManagementComponent } from "./pages/esim-management/esim-management.component";
import { ErrorTrackerComponent } from "./pages/error-tracker/error-tracker.component";
import { ByteFlowComponent } from "./pages/byte-flow/byte-flow.component";

const routes: Routes = [{
    path: "",
    redirectTo: "",
    pathMatch: "full"
}, {
    path: "",
    children: [{
        path: "membership",
        component: MembershipComponent
    }, {
        path: "payments",
        component: PaymentsComponent
    }, {
        path: "tata-lifecycle",
        component: TataLifecycleComponent
    }, {
        path: "user-management",
        component: UserManagementComponent
    }, {
        path: "esim-management",
        component: EsimManagementComponent
    }, {
        path: "byte-flowt",
        component: ByteFlowComponent
    }, {
        path: "promo-codes",
        component: PromoCodesComponent
    }, {
        path: "settings",
        component: SettingsComponent
    }, {
        path: "errors",
        component: ErrorTrackerComponent
    }]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
