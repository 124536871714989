<div class="sub-container">
    <div *ngIf="availablePlans.length > 0" class="all-subscriptions-container">
        <div class="row" *ngIf="user.isBlocked">
            <div class="col" style="color: #bd5059">
                <h4>This user was blocked on {{user.blockedAt | date : "dd-MM-YY HH:mm OO" }} due to fradulent payments.</h4>
            </div>
        </div>
        <div class="row" *ngIf="esimFailure">
            <div class="col" style="color: #bd5059">
                <h4>This user's last subscripton failed to get an AddOn from provider and their eSIM may be in a faulty state. 
                    Try sending a new request, if that fails also unassigning the eSIM is advised.</h4>
            </div>
        </div>
        <div class="row actions-container">
            <div class="col all-action-buttons">
                <div class="btn-group" role="group">
                    <button
                        type="button"
                        class="btn btn-primary"
                        aria-expanded="false"
                        data-bs-toggle="collapse"
                        data-bs-target="#newSubscription"
                        aria-controls="newSubscription"
                    >
                        <span
                            data-bs-toggle="tooltip"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Create a new subscription for the user"
                        >
                            <i class="bi bi-plus-circle-fill"></i>
                        </span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-info"
                        aria-expanded="false"
                        (click)="getSubscriptions()"
                        aria-controls="refresh"
                    >
                        <span
                            data-bs-toggle="tooltip"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Refresh the subscription list"
                        >
                            <i class="bi bi-arrow-clockwise"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="collapse" id="newSubscription">
            <div class="card card-body">
                <div class="row justify-content-between">
                    <div class="col-3">
                        <select
                            #selectPlan
                            class="form-select"
                            [(ngModel)]="selectedPlan"
                        >
                            <option
                                *ngFor="let plan of availablePlans"
                                [ngValue]="plan"
                                default
                            >
                                {{ plan.planType }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="col-3"
                        *ngIf="
                            selectedPlan.planType === planTypes.PREMIUM ||
                            selectedPlan.planType === planTypes.ULTRA ||
                            selectedPlan.planType === planTypes.ADVERTISEMENT
                        "
                    >
                        <select
                            #country
                            class="form-select"
                            [(ngModel)]="selectedCountry"
                        >
                            <option
                                *ngFor="let country of countryList"
                                [value]="country.isoCountryCode"
                                default
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="col-3"
                        *ngIf="
                            selectedPlan.planType === planTypes.PREMIUM ||
                            selectedPlan.planType === planTypes.ULTRA
                        "
                    >
                        <div class="input-group">
                            <span class="input-group-text">Days</span>
                            <input
                                class="form-control"
                                id="floatingInput"
                                #inputField
                                [(ngModel)]="numberOfDays"
                            />
                        </div>
                    </div>
                    <div
                        class="col-3"
                        *ngIf="
                            selectedPlan.planType === planTypes.BYTE_PLAN
                        "
                    >
                        <div class="input-group">
                            <span class="input-group-text">Megabytes</span>
                            <input
                                class="form-control"
                                id="floatingInput"
                                type="number"
                                #inputField
                                [(ngModel)]="numberOfDays"
                            />
                        </div>
                    </div>
                    <div class="col-3 text-end">
                        <button
                            type="button"
                            class="btn btn-primary add"
                            aria-expanded="false"
                            [disabled]="isLoading()"
                            (click)="giveSubscription()"
                        >
                            <i class="bi bi-database-fill-add"></i>
                            &nbsp;&nbsp;Create Subscription
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="listOfActiveSubscriptions.length > 0 && !isLoading()"
        class="sub-table"
    >
        <div class="row">
            <div class="col">
                <h3>Active Subscriptions</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-light table-hover table-sm">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Country</th>
                            <th>Creation Date</th>
                            <th>Update Date</th>
                            <th>End Date</th>
                            <th>Usage</th>
                            <th>Top Up</th>
                            <th>SubId</th>
                            <th>Transaction Id</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let activeSubscription of listOfActiveSubscriptions
                            "
                        >
                            <td>{{ activeSubscription.planType }}</td>
                            <td>
                                <span *ngIf="activeSubscription.countries && activeSubscription.countries.length === 1">
                                    <span *ngFor="let country of activeSubscription.countries">
                                        {{ country }}
                                    </span>
                                </span>
                                <span *ngIf="activeSubscription.countries && activeSubscription.countries.length > 1">
                                    <span *ngFor="let country of activeSubscription.countries">
                                        {{ country }}, &nbsp;
                                    </span>
                                </span>
                                <span *ngIf="!activeSubscription.countries || activeSubscription.countries.length === 0">
                                    {{ activeSubscription.country }}
                                </span>
                            </td>
                            <td>
                                {{
                                    activeSubscription.activeSubscriptionCreationDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>
                                {{
                                    activeSubscription.subscriptionUpdateDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>
                                {{
                                    activeSubscription.activeSubscriptionEndDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="activeSubscription.usedMegabytes > 0"
                                >
                                    <app-progress-bar
                                        [validityMegabytes]="
                                            activeSubscription.validityMegabytes
                                        "
                                        [usedMegabytes]="
                                            activeSubscription.usedMegabytes
                                        "
                                        [isDataExhausted]="
                                            activeSubscription.isDataExhausted
                                        "
                                    ></app-progress-bar>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        activeSubscription.usedMegabytes === 0
                                    "
                                >
                                    0 /
                                    {{
                                        activeSubscription.validityMegabytes
                                    }}
                                    MB
                                </ng-container>
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="activeSubscription.awaitingTopUp"
                                >
                                    {{ activeSubscription.awaitingTopUp }}
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        activeSubscription.topUpCount &&
                                        activeSubscription.awaitingTopUp
                                    "
                                >
                                    /
                                </ng-container>
                                <ng-container
                                    *ngIf="activeSubscription.topUpCount"
                                >
                                    {{ activeSubscription.awaitingTopUp }}
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        !activeSubscription.topUpCount &&
                                        !activeSubscription.awaitingTopUp
                                    "
                                >
                                    0
                                </ng-container>
                            </td>
                            <td>
                                {{ activeSubscription.subscriptionId }}
                                <ng-container
                                    *ngIf="!activeSubscription.subscriptionId && !activeSubscription.isDeletedFromProvider"
                                >
                                    <span
                                        class="stranger-danger"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover focus"
                                        data-bs-custom-class="button-tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="This subscription does not have subscriptionId yet! If it doesn't get one soon after its creation, it means PurchaseAddOn notification has failed from tata!"
                                        >!!!</span
                                    >
                                </ng-container>
                                <ng-container
                                    *ngIf="activeSubscription.isDeletedFromProvider"
                                >
                                    <span
                                        class="stranger-danger"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover focus"
                                        data-bs-custom-class="button-tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="This subscription is deleted from the Provider"
                                        >
                                        <i class="bi bi-trash2-fill"></i>
                                        </span
                                    >
                                </ng-container>
                            </td>
                            <td>{{ activeSubscription.transactionId }}</td>
                            <td class="text-end">
                                <div class="action-button-collection">
                                    <div
                                        class="text-end"
                                        style="max-width: 90px"
                                    >
                                        <ng-container
                                            *ngIf="
                                                activeSubscription.planType ===
                                                    planTypes.PREMIUM ||
                                                activeSubscription.planType ===
                                                    planTypes.ULTRA
                                            "
                                        >
                                            <div
                                                class="input-group"
                                                style="max-width: 90px"
                                            >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder=""
                                                    aria-label="Add days"
                                                    aria-describedby="button-days"
                                                    [(ngModel)]="additionalDays"
                                                />
                                                <button
                                                    class="btn btn-quaternary"
                                                    type="button"
                                                    id="button-days"
                                                    (click)="
                                                        addDaysToSubscription()
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Add days to this subscription"
                                                    >
                                                        <i
                                                            class="bi bi-calendar2-plus-fill"
                                                        ></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div class="">
                                        <div class="btn-group" role="group">
                                            <ng-container
                                                *ngIf="
                                                    !activeSubscription.subscriptionId"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-tertiary"
                                                    aria-expanded="false"
                                                    (click)="
                                                        retryAddOn(
                                                            activeSubscription
                                                        )
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Try sending a new request to the provider"
                                                    >
                                                    <i class="bi bi-send-arrow-up-fill"></i>
                                                </span>
                                                </button>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="
                                                    activeSubscription.planType ===
                                                    planTypes.ADVERTISEMENT
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-secondary"
                                                    aria-expanded="false"
                                                    (click)="
                                                        simulateTimeExpire(
                                                            activeSubscription
                                                        )
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Simulate end of its validity period this subscription"
                                                    >
                                                        <i
                                                            class="bi bi-hourglass-split"
                                                        ></i>
                                                    </span>
                                                </button>
                                            </ng-container>
                                            <ng-container>
                                                <button
                                                    type="button"
                                                    class="btn btn-quinary"
                                                    aria-expanded="false"
                                                    (click)="
                                                        simulateDataExhaust(
                                                            activeSubscription
                                                        )
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Exhaust data of this subscription"
                                                    >
                                                        <i
                                                            class="bi bi-cup-straw"
                                                        ></i>
                                                    </span>
                                                </button>
                                            </ng-container>
                                        </div>
                                        &nbsp;
                                        <div class="btn-group" role="group">
                                            <ng-container
                                                *ngIf="
                                                    activeSubscription.planType ===
                                                        planTypes.PREMIUM ||
                                                    activeSubscription.planType ===
                                                        planTypes.ULTRA
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-info"
                                                    aria-expanded="false"
                                                    (click)="
                                                        topUpSubscription()
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Top up this subscription"
                                                    >
                                                        <i
                                                            class="bi bi-cloud-plus-fill"
                                                        ></i>
                                                    </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-warning"
                                                    aria-expanded="false"
                                                    (click)="
                                                        pauseSubscription()
                                                    "
                                                >
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover focus"
                                                        data-bs-custom-class="button-tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Pause this subscription"
                                                    >
                                                        <i
                                                            class="bi bi-stopwatch-fill"
                                                        ></i>
                                                    </span>
                                                </button>
                                            </ng-container>
                                            <button
                                                type="button"
                                                class="btn btn-danger"
                                                aria-expanded="false"
                                                (click)="
                                                    removeSubscription(
                                                        activeSubscription
                                                    )
                                                "
                                            >
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover focus"
                                                    data-bs-custom-class="button-tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-title="End this subscription"
                                                >
                                                    <i
                                                        class="bi bi-dash-circle-fill"
                                                    ></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div
        *ngIf="listOfFutureSubscriptions.length > 0 && !isLoading()"
        class="sub-table"
    >
        <div class="row">
            <div class="col">
                <h3>Future Subscriptions</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-light table-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col">No of Days</th>
                            <th scope="col">Country</th>
                            <th scope="col-2">Creation Date</th>
                            <th scope="col">Megabytes</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col-3">Transaction Id</th>
                            <th scope="col-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let futureSubscription of listOfFutureSubscriptions
                            "
                        >
                            <td>{{ futureSubscription.numberOfDays }}</td>
                            <td>
                                <span *ngIf="futureSubscription.countries && futureSubscription.countries.length === 1">
                                    <span *ngFor="let country of futureSubscription.countries">
                                        {{ country }}
                                    </span>
                                </span>
                                <span *ngIf="futureSubscription.countries && futureSubscription.countries.length > 1">
                                    <span *ngFor="let country of futureSubscription.countries">
                                        {{ country }}, &nbsp;
                                    </span>
                                </span>
                                <span *ngIf="!futureSubscription.countries || futureSubscription.countries.length === 0">
                                    {{ futureSubscription.country }}
                                </span>
                            </td>
                            <td>
                                {{
                                    futureSubscription.futureSubscriptionCreationDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>
                                {{ futureSubscription.validityMegabytes }} MB
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="
                                        futureSubscription.paymentStatus ===
                                        'PROCESSING'
                                    "
                                >
                                    Processing
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        futureSubscription.paymentStatus ===
                                        'BACKOFFICE'
                                    "
                                >
                                    Backoffice
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        futureSubscription.paymentStatus ===
                                        'FAILED'
                                    "
                                >
                                    Failed
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        futureSubscription.paymentStatus ===
                                        'SUCCESS'
                                    "
                                >
                                    Success
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        futureSubscription.paymentStatus ===
                                        'CANCELLED'
                                    "
                                >
                                    Cancelled
                                </ng-container>
                            </td>
                            <td>{{ futureSubscription.transactionId }}</td>
                            <td class="text-end">
                                <div class="action-button-collection">
                                    <div
                                        class="text-end"
                                        style="max-width: 90px"
                                    >
                                        <div
                                            class="input-group"
                                            style="max-width: 90px"
                                        >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder=""
                                                aria-label="Add days"
                                                aria-describedby="button-days"
                                                [(ngModel)]="additionalDays"
                                            />
                                            <button
                                                class="btn btn-quaternary"
                                                type="button"
                                                id="button-days"
                                                (click)="
                                                    addDaysToSubscription(true)
                                                "
                                            >
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover focus"
                                                    data-bs-custom-class="button-tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-title="Add days to this subscription"
                                                >
                                                    <i
                                                        class="bi bi-calendar2-plus-fill"
                                                    ></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div class="">
                                        <div
                                            class="btn-group"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                aria-expanded="false"
                                                [disabled]="!user.esim"
                                                (click)="activateSubscription(futureSubscription)"
                                            >
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover focus"
                                                    data-bs-custom-class="button-tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-title="Activate this subscription"
                                                >
                                                    <i
                                                        class="bi bi-radioactive"
                                                    ></i>
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-danger"
                                                aria-expanded="false"
                                                (click)="
                                                    removeFutureSubscription(
                                                        futureSubscription
                                                    )
                                                "
                                            >
                                                <i
                                                    class="bi bi-database-fill-dash"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="listOfPaidSubscriptions.length > 0" class="sub-table">
        <div class="row">
            <div class="col">
                <h3>Payment Requests</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-light table-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Payment Reason</th>
                            <th scope="col">Country</th>
                            <th scope="col">Number of Days</th>
                            <th scope="col-2">Request Time</th>
                            <th scope="col-3">Payment Request Id</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let paidSubscription of listOfPaidSubscriptions
                            "
                        >
                            <td>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentReason ===
                                        'NEW_SUBSCRIPTION'
                                    "
                                >
                                    New Subscription for
                                    {{ paidSubscription.planType }}
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentReason ===
                                        'TOP_UP'
                                    "
                                >
                                    Top Up for {{ paidSubscription.planType }}
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentReason ===
                                        'TOP_UP_ALL_DAYS'
                                    "
                                >
                                    Top Up All Days
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentReason ===
                                        'ADD_DAYS_TO_ACTIVE'
                                    "
                                >
                                    Add Days to Active
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentReason ===
                                        'ADD_DAYS_TO_FUTURE'
                                    "
                                >
                                    Add Days to Future
                                </ng-container>
                            </td>
                            <td>
                                <span *ngIf="paidSubscription.countries && paidSubscription.countries.length === 1">
                                    <span *ngFor="let country of paidSubscription.countries">
                                        {{ country }}
                                    </span>
                                </span>
                                <span *ngIf="paidSubscription.countries && paidSubscription.countries.length > 1">
                                    <span *ngFor="let country of paidSubscription.countries">
                                        {{ country }}, &nbsp;
                                    </span>
                                </span>
                                <span *ngIf="!paidSubscription.countries || paidSubscription.countries.length === 0">
                                    {{ paidSubscription.country }}
                                </span>
                            </td>
                            <td>{{ paidSubscription.numberOfDays }}</td>
                            <td>
                                {{ convertTimestampToDate(paidSubscription.createdAt) | date : "dd-MM-YY HH:mm OO"
                            }}
                            </td>
                            <td>{{ paidSubscription.paymentRequestId }}</td>
                            <td>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentStatus ===
                                        'PROCESSING'
                                    "
                                >
                                    Processing
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentStatus ===
                                        'BACKOFFICE'
                                    "
                                >
                                    Backoffice
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentStatus ===
                                        'FAILED'
                                    "
                                >
                                    Failed
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentStatus ===
                                        'SUCCESS'
                                    "
                                >
                                    Success
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        paidSubscription.paymentStatus ===
                                        'CANCELLED'
                                    "
                                >
                                    Cancelled
                                </ng-container>
                            </td>
                            <td class="text-end">
                                <div class="action-button-collection">
                                    <div
                                        class="btn-group"
                                        role="group"
                                    >
                                    <ng-container *ngIf="paidSubscription.paymentStatus === 'PROCESSING'">
                                        <button
                                        type="button"
                                        class="btn btn-info"
                                        aria-expanded="false"
                                        (click)="confirmPaidSubscription(paidSubscription)"
                                    >
                                        <span
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover focus"
                                            data-bs-custom-class="button-tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Confirm payment for this request"
                                        >
                                        <i class="bi bi-credit-card-2-back-fill"></i>
                                        </span>
                                    </button>
                                    </ng-container>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            aria-expanded="false"
                                            (click)="
                                            removePaidSubscription(
                                                paidSubscription
                                                )
                                            "
                                        >
                                        <i class="bi bi-x-octagon-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div
        *ngIf="listOfPastSubscriptions.length > 0 && !isLoading()"
        class="sub-table"
    >
        <div class="row">
            <div class="col">
                <h3>Past Subscriptions</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-light table-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col-2">Type</th>
                            <th scope="col-2">Country</th>
                            <th scope="col-2">Creation Date</th>
                            <th scope="col-2">End Date</th>
                            <th scope="col-3">Transaction Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let pastSubscription of listOfPastSubscriptions
                            "
                        >
                            <td>{{ pastSubscription.planType }}</td>
                            <td>{{ pastSubscription.country }}</td>
                            <td>
                                {{
                                    pastSubscription.activeSubscriptionCreationDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>
                                {{
                                    pastSubscription.pastSubscriptionCreationDate
                                        | date : "dd-MM-YY HH:mm OO"
                                }}
                            </td>
                            <td>{{ pastSubscription.transactionId }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading()" class="text-center">
        <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>
