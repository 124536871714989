import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionHttpService } from '../../services/subscription.http.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { User } from '../../models/users/user';
import { Plan } from '../../models/plans/plan';
import { ProviderHttpService } from '../../services/provider.http.service';
import { PlanType } from '../../enums/plan-type';
import { Country } from '../../models/country';
import * as bootstrap from "bootstrap";
import { RequestTrackerService } from 'src/app/shared/services/request-tracker.service';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.scss']
})
export class ManageSubscriptionsComponent implements OnInit {
  @Input() public user: User;

  private iccId: string;

  public loading: boolean = true;

  public esimFailure: boolean = false;
  public planTypes = PlanType;
  public availablePlans: Plan[] = [];
  public countryList: Country[] = [];
  public listOfActiveSubscriptions: any[] = [];
  public listOfFutureSubscriptions: any[] = [];
  public listOfPastSubscriptions: any[] = [];
  public listOfPaidSubscriptions: any[] = [];


  public selectedPlan: Plan;
  public selectedCountry: string;
  public numberOfDays: number = 1;
  public additionalDays: number = 1;

  constructor(
    private readonly requestTrackerService: RequestTrackerService,
    private readonly subscriptionHttpService: SubscriptionHttpService,
    private readonly toasterService: ToasterService,
    private readonly providerHttpService: ProviderHttpService
  ) { }

  ngOnInit(): void {
    if (this.user.esim) {
      this.iccId = this.user.esim.iccId;
    } else {
      this.iccId = "UNREGISTERED";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getAvailablePlans();
      this.getCountries();
      this.getSubscriptions();
    });
  }

  private getAvailablePlans(): void {
    this.providerHttpService.getPlans(this.user.uid).subscribe((response) => {
      if (this.user.esim) {
        this.selectedPlan = response.data.find(
          (plan) => plan.planType === PlanType.FREEMIUM
        );

        this.availablePlans = response.data;
      } else {
        this.availablePlans = [];
        response.data.forEach((plan) => {
          this.selectedPlan = response.data.find(
            (plan) => plan.planType === PlanType.PREMIUM
          );

          if (plan.planType === PlanType.PREMIUM || plan.planType === PlanType.ULTRA) {
            this.availablePlans.push(plan);
          }
        });
      }
    });
  }

  private getCountries(): void {
    this.providerHttpService.getCountries().subscribe(
      (response) => {
        const global: Country = { isoCountryCode: "", name: "Global (V1 Pricing)" };
        this.countryList.push(global);
        response.data.forEach((country) => {
          this.countryList.push({ isoCountryCode: country.isoCountryCode, name: `${country.name} (V2)` });
        });
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }


  public getSubscriptions(): void {
    this.esimFailure = false;
    this.closeToolTips();

    this.subscriptionHttpService.getSubscriptions(this.user.uid, this.iccId).subscribe(
      (response) => {
        this.listOfActiveSubscriptions = [];
        this.listOfFutureSubscriptions = [];
        this.listOfPastSubscriptions = [];
        this.listOfPaidSubscriptions = [];
        this.listOfActiveSubscriptions = response.activeSubscriptionList;
        this.listOfFutureSubscriptions = response.futureSubscriptionList;
        this.listOfPastSubscriptions = response.pastSubscriptionList;

        this.listOfPastSubscriptions.sort((a, b) => {
          const dateA = new Date(a.pastSubscriptionCreationDate);
          const dateB = new Date(b.pastSubscriptionCreationDate);
          return dateB.getTime() - dateA.getTime();
        });

        try {
          for (const subscription of this.listOfActiveSubscriptions) {
            if (!subscription.subscriptionId && !subscription.isDeletedFromProvider) {
              if (subscription.activeSubscriptionCreationDate) {
                const date = new Date(subscription.activeSubscriptionCreationDate);
                const now = new Date();
                if (now.getTime() - date.getTime() > 60 * 1000 * 10) {
                  this.esimFailure = true;
                }
              }
            }
          }
        } catch {
          console.log("error");
        }

        this.listOfPaidSubscriptions = response.paidSubsciptionsList;
        this.initialiseToolTips();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public giveSubscription(): void {
    let activation: boolean;
    if (this.selectedPlan.planType === PlanType.FREEMIUM ||
      this.selectedPlan.planType === PlanType.THROTTLE ||
      this.selectedPlan.planType === PlanType.LIMITED_PREMIUM) {
      this.selectedCountry = null;
    }
    if (
      this.selectedPlan.planType === PlanType.FREEMIUM ||
      this.selectedPlan.planType === PlanType.ADVERTISEMENT
    ) {
      this.numberOfDays = null;
    }
    this.subscriptionHttpService
      .giveSubscription(
        this.user.uid,
        this.iccId,
        this.selectedPlan.planType,
        this.selectedCountry,
        this.numberOfDays
      )
      .subscribe(
        (response) => {
          //this.closebutton.nativeElement.click();
          this.getSubscriptions();
          this.toasterService.success(
            "Subscription is assigned to the user"
          );
        },
        (error) => {
          this.toasterService.error(`ERROR: ${error.error.message}`);
        }
      );
  }

  public activateSubscription(futureSubscription: any): void {
    this.subscriptionHttpService.activateFutureSubscription(this.user.uid, this.user.esim.iccId, futureSubscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Premium subscription is activated"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public removeSubscription(subscription: any): void {
    this.subscriptionHttpService.deleteSubscription(this.user.uid, this.user.esim.iccId, subscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Subscription is moved to Past"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public removeFutureSubscription(subscription: any): void {
    this.subscriptionHttpService.deleteFutureSubscription(this.user.uid, this.iccId, subscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Subscription is removed"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public removePaidSubscription(subscription: any): void {
    this.subscriptionHttpService.deletePaidSubscription(this.user.uid, this.iccId, subscription.paymentRequestId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Request is deleted"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public confirmPaidSubscription(subscription: any): void {
    this.subscriptionHttpService.paySubscription(this.user.uid, this.iccId, subscription.paymentRequestId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Payment Request is confirmed as paid"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public addDaysToSubscription(future?: boolean): void {
    this.subscriptionHttpService.addDaysToSubscription(this.user.uid, this.iccId, this.additionalDays, future).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "New days are added to subscription"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }

  public pauseSubscription(): void {
    this.subscriptionHttpService.pausePremiumSubscription(this.user.uid, this.user.esim.iccId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Premium subscription is paused"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }

  public simulateTimeExpire(subscription: any): void {
    this.subscriptionHttpService.expireSubscription(this.user.uid, this.user.esim.iccId, subscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Subscription is expired, please wait ~2 minutes before it's picked up by rollers and moved to past."
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }

  public simulateDataExhaust(subscription: any): void {
    this.subscriptionHttpService.exhaustSubscription(this.user.uid, this.user.esim.iccId, subscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Subscription data is exhausted"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }

  public retryAddOn(subscription: any): void {
    this.subscriptionHttpService.retryAddingAddOn(this.user.uid, this.user.esim.iccId, subscription.transactionId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "Subscription request is sent to the provider again"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }

  public topUpSubscription(): void {
    this.subscriptionHttpService.topUpPremiumSubscription(this.user.uid, this.user.esim.iccId).subscribe(response => {
      this.getSubscriptions();
      this.toasterService.success(
        "New data plan is added to subscription"
      );
    },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      });
  }


  public deleteAll(): void {

  }


  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }

  private closeToolTips(): void {
    const tooltipElements = Array.from(document.querySelectorAll('.bs-tooltip-auto'));
    for (const item of tooltipElements) {
      item.remove();
    }
  }

  public convertTimestampToDate(timestamp) {
    const seconds = timestamp._seconds;
    const nanoseconds = timestamp._nanoseconds;

    // Convert the timestamp to milliseconds and create a Date object
    const milliseconds = (seconds * 1000) + (nanoseconds / 1000000);
    const date = new Date(milliseconds);

    // Return the ISO string representation of the date
    return date.toISOString();
  }


}
